var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "行政处罚",
                  "head-btn-options": _vm.headTopBtnOptions,
                },
                on: {
                  "head-cancel": _vm.handleHeadGoback,
                  "head-save": function ($event) {
                    return _vm.headSave(1)
                  },
                  "head-save-back": function ($event) {
                    return _vm.headSave(2)
                  },
                },
              }),
              _c(
                "avue-form",
                {
                  ref: "formLayout",
                  staticStyle: { padding: "10px" },
                  attrs: { option: _vm.formOptions },
                  model: {
                    value: _vm.dataForm,
                    callback: function ($$v) {
                      _vm.dataForm = $$v
                    },
                    expression: "dataForm",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "acceptOrg" },
                    [
                      _c("avue-input-tree", {
                        attrs: {
                          checkStrictly: "",
                          "default-expand-all": "",
                          placeholder: "请填写接受单位",
                          dic: _vm.organDic,
                          props: { label: "deptName", value: "id" },
                          disabled: _vm.type == "view",
                        },
                        on: { change: _vm.handleChangeContract },
                        model: {
                          value: _vm.dataForm.acceptOrg,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "acceptOrg", $$v)
                          },
                          expression: "dataForm.acceptOrg",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "punishArea" },
                    [
                      _c("el-cascader", {
                        ref: "punishArea",
                        attrs: {
                          props: _vm.areaOptions,
                          clearable: "",
                          disabled: _vm.type == "view",
                        },
                        on: { change: _vm.handleAreaChange },
                        model: {
                          value: _vm.dataForm.punishArea,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "punishArea", $$v)
                          },
                          expression: "dataForm.punishArea",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("uploader", {
                ref: "uploader",
                attrs: {
                  fileSize: 500,
                  accept:
                    ".jpg,.png,.pdf,.gif,.jpeg,.ppt,.doc,.docx,.xls,.xlsx",
                  multiple: true,
                  fileOptions: _vm.fileOptions,
                  gridRowBtn: _vm.fileGridRowBtn,
                  disabled: _vm.type == "view",
                },
                on: { getTableData: _vm.handleUpload },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }